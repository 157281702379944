import React from 'react';
import Img from "gatsby-image"
import { Link, navigate } from "gatsby"
import { List, Button, Row, Col, Pagination } from 'antd';
import "../theme/index.scss"
import { FormattedDate } from "gatsby-plugin-intl"

const BlogListComponent = ({data, pageContext, location}) => {
    return (
        <div className="container blog-list">
        <List 
          itemLayout='horizontal'
          dataSource={data.allMarkdownRemark.edges}
          renderItem={({ node }) => (
            <List.Item id={node.frontmatter.slug} className="blog-list_post">
              <Row type="flex" justify="start" gutter={16} style={{ width: '100%' }}>
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                  {node.photo_principale ?
                      <div style={{ display: "flex", height:"100%" }}>
                        <Img fluid={node.photo_principale.childImageSharp.fluid} style={{ width:"100%" }} alt="Logo Le Cèdre Enchanté"/>
                      </div>
                      :
                      <div className="blog-list_post_img">
                        <Img fluid={data.logo.childImageSharp.fluid} style={{ width:"200px" }} alt="Logo Le Cèdre Enchanté"/>
                      </div>
                  }
                </Col>
                <Col xs={24} sm={24} md={10} lg={10} xl={10} >
                  <List.Item.Meta
                    title= {
                      <span style={{fontSize: "18px"}}>
                        <Link to={`/${node.frontmatter.slug}`}>
                        {node.frontmatter.title}</Link>
                      </span>
                    }
                    description={
                      <div>
                        <FormattedDate value={node.frontmatter.date} year="numeric" month="long" day="2-digit"/>
                        <p style={{ paddingTop: "16px", lineHeight: '1.25' }}>
                          {node.excerpt}
                        </p>
                        <Button className="readMore" >
                          <Link to={`/${node.frontmatter.slug}`} style={{ color: '#8ab7ab' }}>En savoir plus</Link>
                        </Button>
                      </div>
                    }
                  />
                </Col>
              </Row>
            </List.Item>
          )}
        />
        <Pagination style={{ display: "flex", justifyContent: "center"}} current={pageContext.currentPage} total={pageContext.numPages*pageContext.limit} onChange={(event) => event === 1 ? navigate(`${location.pathname}`) : navigate(`${location.pathname}/${event}`)}/>

      </div>
    );
};

export default BlogListComponent;