import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BlogListComponent from '../components/blog-list';

const BlogPage = ({ data, pageContext, location }) => {
  return (
    <Layout page='blog' bgColorDark={true}>
      <SEO title="Elevage" keywords={[`Elevage`, `Cedrenchante`, `chiens`, `chats`, `chiots`, `chatons`, `berger des shetland`, `chihuahua`, `epagneul tibetain`, `british shorthair`]} />
      <BlogListComponent data={data} pageContext={pageContext} location={location} />
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query BlogQuery($limit: Int!, $skip: Int!) {
    allMarkdownRemark( 
      sort: {fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: {template : { eq: "post" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            slug
          }
          photo_principale {
            childImageSharp {
              fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          excerpt
        }
      }
    }
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }`;